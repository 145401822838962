const products = [
  {
    id: 1,
    name: 'Onto the Sunset',
    price: 20,
    imageUrl: '/prod.jpg',
    description: 'A Kentucky based classic Italian blend bound to offer a complex and smoky flavour profile.',
    outOfStock: false,
  },
  {
    id: 2,
    name: 'Fresh as Daylight',
    price: 20,
    imageUrl: 'prod2.jpg',
    description: 'Unavailable',
    outOfStock: true,
  },
  {
    id: 3,
    name: 'Into the Woodlands',
    price: 20,
    imageUrl: 'prod3.jpg',
    description: 'An invigorating blend of Virginia, Black Cavendish and Latakia offering a unique earthy and spicy taste that can only be truly described after tasting this wonder.',
    outOfStock: false,
  },
  {
    id: 4,
    name: 'Onto the Wild',
    price: 20,
    imageUrl: 'prod4.jpg',
    description: 'Unavailable',
    outOfStock: true,
  },
  {
    id: 5,
    name: 'Into the Bleak Midwinter',
    price: 20,
    imageUrl: 'prod5.png',
    description: 'A traditional authentic cigarette taste. This liquid has been specially crafted with cigarette smokers in mind and will leave you persistent dry taste in your mouth. To those looking looking for the analogue cigarette taste and finding it difficult to transition to vaping, your search is over. If this doesn\'t stop you from smoking, we don\'t know what will.',
    outOfStock: false,
  },
  {
    id: 6,
    name: 'Daily Harmony',
    price: 20,
    imageUrl: 'prod6.png',
    description: 'A reinvented take on the popular American Blend reminiscent of perfectly ground tobacco.',
    outOfStock: false,
  },
  {
    id: 51,
    name: 'Onto the Sunset INTENSE',
    price: 30,
    imageUrl: 'prod51.png',
    description: 'A Kentucky based classic Italian blend bound to offer a complex and smoky flavour profile.<br>A much more concentrated flavor for your palate to savour.',
    outOfStock: false,
  },
  {
    id: 52,
    name: 'Fresh as Daylight INSTENSE',
    price: 30,
    imageUrl: '/prod52.png',
    description: 'Unavailable<br>A much more concentrated flavor for your palate to savour.',
    outOfStock: true,
  },
  {
    id: 53,
    name: 'Into the Woodlands INTENSE',
    price: 30,
    imageUrl: 'prod53.png',
    description: 'An invigorating blend of Virginia, Black Cavendish and Latakia offering a unique earthy and spicy taste that can only be truly described after tasting this wonder.<br>A much more concentrated flavor for your palate to savour.',
    outOfStock: false,
  },
  {
    id: 54,
    name: 'Onto the Wild INSTENSE',
    price: 30,
    imageUrl: '/prod54.png',
    description: 'Unavailable<br>A much more concentrated flavor for your palate to savour.',
    outOfStock: true,
  },
  {
    id: 55,
    name: 'Into the Bleak Midwinter INSTENSE',
    price: 30,
    imageUrl: '/prod55.png',
    description: 'A traditional authentic cigarette taste. This liquid has been specially crafted with cigarette smokers in mind and will leave you persistent dry taste in your mouth. To those looking looking for the analogue cigarette taste and finding it difficult to transition to vaping, your search is over. If this doesn\'t stop you from smoking, we don\'t know what will.<br>A much more concentrated flavor for your palate to savour.',
    outOfStock: false,
  },
  {
    id: 56,
    name: 'Daily Harmony INSTENSE',
    price: 30,
    imageUrl: '/prod56.png',
    description: 'A reinvented take on the popular American Blend reminiscent of perfectly ground tobacco.<br>A much more concentrated flavor for your palate to savour.',
    outOfStock: false,
  },
  // Add more products as needed
];

export default products;
