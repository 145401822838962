import { useRef, useState } from "react";
import axios from "axios";
import "./contact.css";

const __successClassName = "successText";
const __errorClassName = "errorText";
const __placeHolderClassName = "feedbackPlaceHolder";

const __placeHolderFeedback = {
  className: __placeHolderClassName,
  message: <>&nbsp;</>,
};

function Contact() {
  const nameRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();

  const [feedback, setFeedback] = useState(__placeHolderFeedback);
  const [loading, setLoading] = useState(false);

  const displayError = () => {
    setFeedback({
      className: __errorClassName,
      message: "Something went wrong!",
    });
  };

  const displaySuccess = () => {
    setFeedback({
      className: __successClassName,
      message: "Your message was sent successfully!",
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);

    try {
      const response = await axios.post(
        "https://script.google.com/macros/s/AKfycbwO-EdyUY4rP8GwTsrRd_KeKkLgF4tLJQI9vY0zPgHrU2TeI6EIOsWqztlUtXXLOuiK4Q/exec",
        JSON.stringify({
          name: nameRef.current.value,
          email: emailRef.current.value,
          message: messageRef.current.value,
        }),
        {
          headers: {
            "Content-Type": "text/plain;charset=utf-8",
          },
        }
      );

      if (
        !response.data ||
        !response.data.result ||
        !response.data.result === "success"
      ) {
        throw new Error("Something went wrong!");
      }

      displaySuccess();
      nameRef.current.value = "";
      emailRef.current.value = "";
      messageRef.current.value = "";
    } catch (e) {
      displayError();
    }

    setLoading(false);
  };

  return (
  <div className="App-body contact-outer">
    <div className="contact" id="contact">
      <h1 className="section-title">Contact</h1>

      <div className="inner-contact">
        <div className="info-contact">
          <h2>To become an Official agent:</h2>

          <p>
            Contact <i>sales@roadvapes.com</i>
          </p>
        </div>
        <div className="input-contact">
          <form onSubmit={handleSubmit}>
            <span className="responseMessage"></span>
            <input placeholder="Name" ref={nameRef} required />
            <input
              className="emailInput"
              placeholder="Email"
              type="email"
              ref={emailRef}
              required
            />
            <textarea
              placeholder="Message"
              type="text"
              ref={messageRef}
              required
            ></textarea>

            <div className={feedback.className}>{feedback.message}</div>

            <input
              type="submit"
              value={"Send Message"}
              className={loading ? "sendButtonDisabled" : "sendButton"}
              disabled={loading}
            />
          </form>
        </div>
      </div>
	  </div>
	  
	  <div className="footer" >
		<div className="cr">
		Copyright © ROAD 2024
		</div>
	  </div>
    </div>
  );
}

export default Contact;
