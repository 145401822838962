import "./App.css";
import products from "./products";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Contact from "./contact";

function App() {
  const [selectedProduct, setSelectedProduct] = useState(
    products[0] ? products[0] : null
  );
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Calculate the indexes of the products to show on the current page
  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = products.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  // Function to handle product selection
  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const scrollToSecond = () => {
    document.getElementById("second").scrollIntoView({ behavior: "smooth" });
  };
  
  const scrollToContact = () => {
    document.getElementById("contact").scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        //console.log(entries[0].intersectionRatio)
        if (entries[0].isIntersecting && entries[0].intersectionRatio >= 0.1) {
          // When the observed element is in view, add the 'hidden' class
          document
            .querySelector(".Index-page-scroll-indicator")
            .classList.add("hidden");
        } else {
          // When the observed element is out of view, remove the 'hidden' class
          document
            .querySelector(".Index-page-scroll-indicator")
            .classList.remove("hidden");
        }
      },
      { threshold: [0, 0.1] }
    );

    const secondSection = document.getElementById("second");
    observer.observe(secondSection);

    // Clean up the observer when component unmounts
    return () => {
      observer.unobserve(secondSection);
    };
  }, []);

  // Handle 'Next' button click
  const handleNext = () => {
    if (currentPage < Math.ceil(products.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Handle 'Prev' button click
  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  /*<span className="bottles">
			<img className="smaller" src={bottle} alt="Bottle" />
			<img src={bottle} alt="Bottle" />
			<img className="smaller" src={bottle} alt="Bottle" />
		</span>
		*/
  return (
    <div className="App">
      <header className="App-header">
        <Helmet>
          <title>Road Vapes | NET e-liquid</title>
          <meta
            name="description"
            content="Road is naturally extracted tobacco provider for vapers with exquisite eliquid tastes."
          />
          <meta
            name="keywords"
            content="road,vape,net,eliquid,natural,tobacco,roadvapes"
          />
          <meta name="author" content="Road Vapes" />
          <meta property="og:title" content="Road Vapes | NET e-liquid" />
          <meta
            property="og:description"
            content="Road is naturally extracted tobacco provider for vapers with exquisite eliquid tastes."
          />
          <meta property="og:url" content="https://roadvapes.com" />
        </Helmet>

        <span className="inner-header">
          {/*<svg
            xmlns="http://www.w3.org/2000/svg"
            height="32"
            width="28"
            viewBox="0 0 448 512"
          >
            <path
              fill="white"
              d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
            />
		</svg>*/}
		
		<input type="checkbox" id="openmenu" className="hamburger-checkbox"/>
  
		  <div className="hamburger-icon">
			<label for="openmenu" id="hamburger-label">
			  <span></span>
			  <span></span>
			  <span></span>
			</label>    
		  </div>
          <p>ROAD</p>
          <p onClick={scrollToContact} className="clickable">Contact</p>
		  
		  <div className="menu-pane">
      
			  <nav>
				<ul className="menu-links">
				  <li><a href="mailto:contact@roadvapes.com">Info</a><span id="QC-info">
					<p>contact@roadvapes.com</p>
				  </span>
					
				  </li>
				  
				  <li><a href="/health">health</a>
				   
				  </li>
				  <li><a href="/faq">faq</a></li>
				  <li><a href="/about">about</a></li>
				</ul>
				 <ul className="menu-links">
							 <li><a href="mailto:sales@roadvapes.com">Business</a>
							   <span id="DC-info">
					<p>sales@roadvapes.com</p>
				  </span></li>

				  <li><a href="/ingredients">ingredients</a></li>
				  <li><a href="/making-of-road">
					how it's made            
					</a></li>
				  <li><a href="/choosing">how do i choose ?</a></li>
				</ul>
				
				
			  </nav>
			</div>
        </span>
		
      </header>

      <div className="App-body first">
        <h2 className="Main-text">NATURAL TOBACCO EXTRACTS</h2>
      </div>

      <div className="Index-page-scroll-indicator" onClick={scrollToSecond}>
        <div className="Index-page-scroll-indicator-line"></div>
      </div>

      <div className="App-body second" id="second">
        <h1 className="section-title">
          Finely selected for Tobacco connoisseur
        </h1>

        <div className="Inner-second">
          <div className="product-info">
            {selectedProduct ? (
              <div className="inner-product-info">
                <div>
                  <h2>
                    <u>More about the craft:</u>
                  </h2>
                  <h2>{selectedProduct.name}</h2>
                  <p>Price: ${selectedProduct.price}</p>
                  <p className="product-description">
                    <u>Description:</u>{" "}
                    {selectedProduct.description.split("<br>")[0]}
                  </p>
                  {selectedProduct.description.split("<br>")[1] ? (
                    <p className="product-description">
                      {selectedProduct.description.split("<br>")[1]}
                    </p>
                  ) : null}
                  {/* Display more details if needed */}
                </div>

                <div>
                  <h3>
                    <u>Note:</u> Designed for MTL vaping & operating temperature
                    ~180-200°C(max). ex: 0.8 ohms @ 18 Watts.
                  </h3>
				  <div className="pagination">
            <button onClick={handlePrev} disabled={currentPage === 1}>
              Prev
            </button>
            <button
              onClick={handleNext}
              disabled={
                currentPage === Math.ceil(products.length / itemsPerPage)
              }
            >
              Next
            </button>
          </div>
                </div>
				
              </div>
            ) : (
              <p>No products available !</p>
            )}
          </div>

          <div className="product-grid">
            {currentProducts.map((product) => (
              <div
                key={product.id}
                className={`product ${
                  selectedProduct === product ? "selected" : ""
                }`}
                onClick={() => handleProductClick(product)}
              >
                {product.outOfStock ? (
                  <span
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    Out of Stock
                  </span>
                ) : null}
                <div className="product-image">
                  <img src={product.imageUrl} alt={product.name} />
                </div>
                <div className="product-details">
                  <h3>{product.name}</h3>
                  <p>Price: ${product.price}</p>
                  {/* Add more details if needed */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Contact />
    </div>
  );
}

export default App;
